
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import { mapGetters, mapState } from 'vuex';
import ManagementMixin from '@/mixins/ManagementMixin';
import CommonMixin from '@/mixins/CommonMixin';
import { createColumn } from '@/util/table';
import { handleErrorUI } from '@/util/error';
import PaginationMixin from '@/mixins/PaginationMixin';
import { checkPermission } from '@/api/utils';

export default {
  mixins: [ManagementMixin, CommonMixin, PaginationMixin],
  data() {
    return {
      brands: [],
      showDialog: false,
      selectedBrand: { id: null, code: '', brand: '' },
      selectedBrandName: null,
      deleteConfirmationShown: false,
      loading: false,
      columns: [],
      searchKeyword: ['', ''],
      rowData: []
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount'])
  },
  watch: {
    brands() {
      this.rowData = this.brands;
    },
    searchKeyword() {
      let obj = {
        code: this.searchKeyword[0],
        brand: this.searchKeyword[1]
      };
      let tbData = Object.values(this.brands);
      let filtered = tbData.map((dat, key) => {
        return {
          code: dat['code'],
          brand: dat['brand']
        };
      });
      this.rowData = filtered.filter(v => {
        return String(v[this.parameter])
          .toLowerCase()
          .includes(obj[this.parameter].toLowerCase());
      });
    }
  },
  async created() {
    this.columns = [
      createColumn('code', this.$t('SettingsManagement.code')),
      createColumn('brand', this.$t('SettingsManagement.name')),
      createColumn('action', this.$t('SettingsManagement.actions'), {
        sortable: false
      })
    ].filter(Boolean);
    await this.fetchBrands();
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    async fetchBrands() {
      this.loading = true;
      try {
        const { data, pagination } = await this.$repo.brand.getBrands(
          this.pagination
        );
        this.brands = data;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    showAddDialog() {
      this.selectedBrand = this.nullBrand();
      this.showDialog = true;
    },
    showEditDialog(brand) {
      this.selectedBrand = { ...brand };
      this.selectedBrandName = brand.brand;
      this.showDialog = true;
    },
    closeDialog() {
      this.selectedBrand = this.nullBrand();
      this.showDialog = false;
    },
    async onDelete() {
      try {
        var exists = this.selectedBrand.id;
        await this.$repo.brand.deleteBrand(this.selectedBrand.id);
        await this.fetchBrands();
        var check = 0;
        for (let i = 0; i < this.brands.length; i++) {
          if (exists === this.brands[i].id) {
            check = 1;
          }
        }
        if (check === 1) {
          this.$q.notify({
            type: 'negative',
            message: this.$t('BrandManagement.deleteErrorMessage'),
            position: 'top'
          });
        } else {
          this.$q.notify({
            type: 'positive',
            message: this.$t('SettingsManagement.successfully_deleted'),
            position: 'top'
          });
        }
      } catch (e) {
        this.$q.notify({
          type: 'negative',
          message: this.$t('BrandManagement.deleteErrorMessage'),
          position: 'top'
        });
      }
    },
    showDeleteConfirmation(brand) {
      this.selectedBrand = brand;
      this.deleteConfirmationShown = true;
    },
    async upsertBrand() {
      try {
        this.loading = true;
        this.$v.selectedBrand.$touch();
        if (!this.$v.selectedBrand.$invalid) {
          if (this.selectedBrand.id != null) {
            const updated = await this.$repo.brand.updateBrand(
              this.selectedBrand.id,
              this.selectedBrand
            );
            const notUpdated = this.brands.filter(
              brand => brand.id !== this.selectedBrand.id
            );
            this.$q.notify({
              type: 'positive',
              message: this.$t('BrandManagement.updateSuccessful'),
              position: 'top'
            });
            this.brands = [updated, ...notUpdated];
          } else {
            const brand = await this.$repo.brand.createBrand(
              this.selectedBrand
            );
            this.$q.notify({
              type: 'positive',
              message: this.$t('BrandManagement.addSuccessful'),
              position: 'top'
            });
            this.brands.unshift(brand);
          }

          this.closeDialog();
        }
      } catch (e) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: this.$t('BrandManagement.addError')
        });
      } finally {
        this.loading = false;
        this.$v.selectedBrand.$reset();
      }
    },
    nullBrand() {
      return { id: null, code: '', brand: '' };
    },
    async paginationChangeHandler() {
      await this.fetchBrands();
    }
  },
  validations: {
    selectedBrand: {
      code: {
        required
      },
      brand: {
        required
      }
    }
  }
};
